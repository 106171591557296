<template>
  <div>
    <EditTemplateDialog
      v-model="isOpenDialog"
      title="งานจ้างเหมาวิธีตกลงราคา"
      :is-add="isAdd"
      :isUpdateLoading="isLoading"
      :readonly="readonly"
      @delete="deleteMaintenance()"
      @clear="clearForm()"
      @close="close()"
      @confirm="confirm()"
    >
      <div class="pa-4">
        <v-form
          ref="form"
          lazy-validation
          class="pt-0"
          enctype="multipart/form-data"
        >
          <v-row>
            <v-col
              v-for="(form, formindex) in fromEditField"
              :key="formindex"
              :sm="form.col"
              cols="12"
            >
              <v-row align="center">
                <v-col
                  class="form-text"
                  :class="$vuetify.breakpoint.xs && 'pb-0'"
                  :sm="form.coltext"
                  cols="12"
                >
                  <span v-text="form.name"></span>
                  <span v-if="form.isrequired" class="red--text pl-1">*</span>
                </v-col>
                <v-col
                  :class="$vuetify.breakpoint.xs && 'pt-0'"
                  :sm="form.colfield"
                  cols="12"
                >
                  <v-text-field
                    v-if="form.type === 'text'"
                    v-model="editForm[form.model]"
                    outlined
                    dense
                    hide-details="auto"
                    :disabled="isLoading"
                    :readonly="readonly"
                    :rules="form.isrequired ? [rules.required] : []"
                  ></v-text-field>
                  <v-text-field
                    v-else-if="form.type === 'number'"
                    v-model="editForm[form.model]"
                    :placeholder="form.placeholder ? form.placeholder : '0'"
                    class="right-input"
                    type="number"
                    min="0"
                    outlined
                    dense
                    hide-details="auto"
                    :disabled="isLoading"
                    :readonly="readonly"
                    :rules="form.isrequired ? [rules.required] : []"
                  ></v-text-field>
                  <DatePicker
                    v-else-if="form.type === 'date'"
                    v-model="editForm[form.model]"
                    :rules="form.isrequired ? [rules.required] : []"
                    class="mt-1"
                    :disabled="isLoading"
                    :readonly="readonly"
                    hide-details="auto"
                  />
                  <v-textarea
                    v-else-if="form.type === 'textarea'"
                    v-model="editForm[form.model]"
                    type="number"
                    outlined
                    dense
                    hide-details="auto"
                    :disabled="isLoading"
                    :readonly="readonly"
                    :rules="form.isrequired ? [rules.required] : []"
                  ></v-textarea>
                  <!-- <v-select
                    v-else-if="form.model === 'bridge_id'"
                    v-model="editForm[form.model]"
                    placeholder="เลือก"
                    outlined
                    dense
                    hide-details="auto"
                    :items="itemLists[form.select.name]"
                    :item-text="form.select.text"
                    :item-value="form.select.value"
                    clearable
                    :rules="form.isrequired ? [rules.required] : []"
                  >
                    <template v-slot:selection="{ item }">
                      <div>
                        <span>
                          {{ item.name }}
                          {{ item.direction ? `(${item.direction})` : '' }}
                        </span>
                      </div>
                    </template>
                    <template v-slot:item="{ item }">
                      <div>
                        {{ item.name }}
                        {{ item.direction ? `(${item.direction})` : '' }}
                      </div>
                    </template>
                  </v-select> -->
                  <v-select
                    v-else-if="form.type === 'select'"
                    v-model="editForm[form.model]"
                    placeholder="เลือก"
                    outlined
                    dense
                    hide-details="auto"
                    :items="itemLists[form.select.name]"
                    :item-text="form.select.text"
                    :item-value="form.select.value"
                    :clearable="!readonly"
                    :disabled="isLoading"
                    :readonly="readonly"
                    :rules="form.isrequired ? [rules.required] : []"
                  ></v-select>
                  <v-autocomplete
                    v-else-if="form.model === 'bridge_id'"
                    v-model="editForm[form.model]"
                    outlined
                    dense
                    :clearable="!readonly"
                    hide-details="auto"
                    :rules="[rules.required]"
                    :items="bridgeListByRoad"
                    :item-text="form.autocomplete.text"
                    :item-value="form.autocomplete.value"
                    :disabled="isLoading"
                    :readonly="readonly"
                    placeholder="เลือก"
                  >
                    <template v-slot:item="{ item }">
                      <div>
                        {{ item.name }}
                        {{ item.direction ? `(${item.direction})` : "" }}
                      </div>
                    </template>
                    <template v-slot:selection="{ item }">
                      <div>
                        <span>
                          {{ item.name }}
                          {{ item.direction ? `(${item.direction})` : "" }}
                        </span>
                      </div>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </EditTemplateDialog>
    <ConfirmDialog
      v-model="isConfirmEditDialog"
      :dialog-title="'ยืนยันการแก้ไขข้อมูล ?'"
      @confirm="edit()"
      @cancel="closeConfirm()"
    />
  </div>
</template>

<script>
import EditTemplateDialog from './EditTemplateDialog.vue'
import DatePicker from '@/components/DatePicker.vue'
import ConfirmDialog from '@/components/dialog/confirmDialog.vue'
export default {
  components: {
    EditTemplateDialog,
    DatePicker,
    ConfirmDialog
  },
  props: {
    value: Boolean,
    maintenanceInfo: Object,
    isAdd: Boolean,
    readonly: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value (newVal, oldVal) {
      this.isOpenDialog = newVal
      if (newVal) {
        if (!this.isAdd) {
          this.editForm = { ...this.maintenanceInfo }
        } else {
          if (this.$refs.form) this.$refs.form.reset()
          this.editForm = {}
        }
      }
    },
    'editForm.road_id' (newVal, oldVal) {
      if (newVal) {
        this.getSectionPart(newVal)
      } else {
        delete this.editForm.section_part_id
        this.itemLists.control = []
      }
    }
  },
  data: () => ({
    isOpenDialog: false,
    fromEditField: [
      {
        name: 'ปีงบประมาณ',
        model: 'budget_year',
        placeholder: (new Date().getFullYear() + 543).toString(),
        col: 12,
        coltext: 2,
        colfield: 4,
        type: 'number',
        isrequired: true
      },
      {
        name: 'หมายเลขทางหลวง',
        model: 'road_id',
        col: 6,
        coltext: 4,
        colfield: 8,
        type: 'select',
        isrequired: true,
        select: {
          name: 'road',
          text: 'label',
          value: 'road_id'
        }
      },
      {
        name: 'ตอนควบคุม',
        model: 'section_part_id',
        col: 6,
        coltext: 4,
        colfield: 8,
        type: 'select',
        isrequired: true,
        select: {
          name: 'control',
          text: 'label',
          value: 'section_part_id'
        }
      },
      {
        name: 'สะพาน',
        model: 'bridge_id',
        col: 12,
        coltext: 2,
        colfield: 10,
        type: 'autocomplete',
        isrequired: true,
        autocomplete: {
          name: 'bridgeList',
          text: 'name',
          value: 'id'
        }
      },
      {
        name: 'ที่',
        model: 'no',
        col: 6,
        coltext: 4,
        colfield: 8,
        type: 'number',
        isrequired: true
      },
      {
        name: 'เลขที่ใบสั่ง',
        model: 'quotation_no',
        col: 6,
        coltext: 4,
        colfield: 8,
        type: 'text',
        isrequired: true
      },
      {
        name: 'บริษัท',
        model: 'company',
        col: 6,
        coltext: 4,
        colfield: 8,
        type: 'text',
        isrequired: true
      },
      {
        name: 'งาน',
        model: 'task',
        col: 6,
        coltext: 4,
        colfield: 8,
        type: 'text',
        isrequired: true
      },
      {
        name: 'จำนวนเงิน (บาท)',
        model: 'price',
        col: 6,
        coltext: 4,
        colfield: 8,
        type: 'number',
        isrequired: true
      },
      {
        name: 'เลขที่หมวด',
        model: 'category_no',
        col: 6,
        coltext: 4,
        colfield: 8,
        type: 'text',
        isrequired: true
      },
      {
        name: 'เลขที่โครงการ',
        model: 'project_no',
        col: 6,
        coltext: 4,
        colfield: 8,
        type: 'text',
        isrequired: true
      },
      {
        name: 'เลขที่โครงการย่อย',
        model: 'project_no_sub',
        col: 6,
        coltext: 4,
        colfield: 8,
        type: 'text',
        isrequired: true
      },
      {
        name: 'กม. - กม.',
        model: 'detail',
        col: 12,
        coltext: 2,
        colfield: 10,
        type: 'text',
        isrequired: true
      },
      {
        name: 'วันที่',
        model: 'project_date',
        col: 6,
        coltext: 4,
        colfield: 8,
        type: 'date',
        isrequired: true
      },
      {
        name: 'วันที่ส่งเบิก',
        model: 'sent_date',
        col: 6,
        coltext: 4,
        colfield: 8,
        type: 'date',
        isrequired: true
      }
    ],
    itemLists: {
      road: [],
      control: []
    },
    editForm: {},
    rules: {
      required: (value) => !!value || 'กรุณากรอกข้อมูล',
      requiredAutocomplete: (v) => !!(v && v.length) || 'กรุณากรอกข้อมูล'
    },
    isConfirmEditDialog: false,
    isLoading: false
  }),
  computed: {
    bridgeListByRoad () {
      if (this.editForm.section_part_id) {
        return this.itemLists.bridgeList.filter(
          (bridge) =>
            bridge.section_part_id === parseInt(this.editForm.section_part_id)
        )
      } else if (this.editForm.road_id) {
        const road = this.itemLists.road.find(
          (road) => road.road_id === this.filterForm.road_id
        )
        return this.itemLists.bridgeList.filter(
          (bridge) => bridge.code_road === road.code
        )
      } else return []
    }
  },
  async mounted () {
    await this.getRefRoad()
    await this.getBridgeList()
  },
  methods: {
    confirm () {
      if (this.$refs.form.validate()) {
        this.isConfirmEditDialog = true
      }
    },
    async edit () {
      this.isLoading = true
      let isEdit = null
      this.isConfirmEditDialog = false
      const editForm = { ...this.editForm }
      editForm.budget_year -= 543
      if (this.isAdd) {
        editForm.id = 0
        isEdit = await this.$store.dispatch(
          'maintenance/setMaintenanceContractPrice',
          editForm
        )
      } else {
        isEdit = await this.$store.dispatch(
          'maintenance/setMaintenanceContractPrice',
          editForm
        )
      }
      if (isEdit) {
        this.isLoading = false
        this.$emit('update')
      }
    },
    confirmDialog () {},
    close () {
      this.$emit('close')
    },
    closeConfirm () {
      this.isConfirmEditDialog = false
    },
    async deleteMaintenance () {
      const isDelete = await this.$store.dispatch(
        'maintenance/delMaintenanceContractPrice',
        {
          id: this.editForm.id
        }
      )
      if (isDelete) {
        this.$emit('update')
      }
    },
    async getRefRoad () {
      const roads = await this.$store.dispatch('getRefRoad', {})
      for (const road of roads) {
        road.label = `${parseInt(road.code)}`
      }
      this.itemLists.road = roads
    },
    async getSectionPart (roadid) {
      const controls = await this.$store.dispatch('ref/getRef', {
        method: `section_part/${roadid}`
      })
      for (const control of controls) {
        control.label = `${control.code} - ${control.name}`
      }
      this.itemLists.control = controls
      const sectionPart = this.itemLists.control.find(
        (section) => section.section_part_id === this.editForm.section_part_id
      )
      if (!sectionPart) {
        delete this.editForm.section_part_id
      }
    },
    clearForm () {
      this.editForm = {}
      this.$refs.form.reset()
    },
    async getBridgeList () {
      const bridgeList = await this.$store.dispatch('getBridgeList', {})
      this.itemLists.bridgeList = []
      bridgeList.forEach((section) => {
        this.itemLists.bridgeList.push({
          header: `ทางหลวงหมายเลข ${parseInt(section.code_road)} - ${
            section.name
          }`,
          code_road: section.code_road,
          section_part_id: section.section_part_id
        })
        section.items.map((bridge) => {
          bridge.section_part_id = section.section_part_id
          bridge.code_road = section.code_road
        })
        this.itemLists.bridgeList.push(...section.items)
      })
    }
  }
}
</script>

<style scoped>
.right-input >>> input {
  text-align: right;
}
</style>
